import React from 'react';
import { asyncComponent } from '@jaredpalmer/after';
import { EJAEnabled } from '../config';
import Home from '../pages/Home';
import { UnifiedRedirect } from '../components';
import { generateSitemapByURLCode } from '../lib/sitemap-generation';

const courseListLoader = () =>
  import(/* webpackChunkName: "CourseList" */ '../pages/CourseList');

const generateSitemapForCourseDetails = (degree) => () =>
  generateSitemapByURLCode(degree);

export default [
  {
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/cursos',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "Courses" */ '../pages/Courses'),
      chunkName: 'Courses',
    }),
  },
  {
    path: '/graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  {
    path: '/pos-graduacao',
    exact: true,
    component: asyncComponent({
      loader: courseListLoader,
      chunkName: 'CourseList',
    }),
  },
  ...(EJAEnabled
    ? [
        {
          path: '/eja',
          exact: true,
          component: asyncComponent({
            loader: courseListLoader,
            chunkName: 'CourseList',
          }),
        },
      ]
    : []),
  {
    path: '/graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsUndergraduate" */ '../pages/CourseDetailsUndergraduate'
        ),
      chunkName: 'CourseDetailsUndergraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('UNDERGRADUATE'),
    },
  },
  {
    path: '/pos-graduacao/:urlCode',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(
          /* webpackChunkName: "CourseDetailsGraduate" */ '../pages/CourseDetailsGraduate'
        ),
      chunkName: 'CourseDetailsGraduate',
    }),
    sitemap: {
      urlCode: generateSitemapForCourseDetails('GRADUATE'),
    },
  },
  ...(EJAEnabled
    ? [
        {
          path: '/eja/:urlCode',
          exact: true,
          component: asyncComponent({
            loader: () =>
              import(
                /* webpackChunkName: "CourseDetailsEJA" */ '../pages/CourseDetailsEJA'
              ),
            chunkName: 'CourseDetailsEJA',
          }),
          sitemap: {
            urlCode: generateSitemapForCourseDetails('EJA'),
          },
        },
      ]
    : []),
  {
    path: '/tim',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
  },
  {
    path: '/parceria',
    exact: true,
    // eslint-disable-next-line react/display-name
    component: () => (
      <UnifiedRedirect pathname="/parceria/tim" preserveQuerystrings={true} />
    ),
  },
  {
    path: '/parceria/tim',
    exact: true,
    component: asyncComponent({
      loader: () => import(/* webpackChunkName: "Tim" */ '../pages/Tim'),
      chunkName: 'Tim',
    }),
  },
  {
    path: '/parceria/tim-vendedor',
    exact: true,
    component: asyncComponent({
      loader: () =>
        import(/* webpackChunkName: "TimSeller" */ '../pages/TimSeller'),
      chunkName: 'TimSeller',
    }),
  },
];
