import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  cx,
  css,
  px2grid,
  px2rem,
  getSpaceSize,
  Text,
  PrimaryButton,
  Button,
  GradientLinearWarning,
  marginSize,
  mediaQuery,
  getColor,
} from '@ampli/ui';
import { Search as SearchIcon, DoubleChevron } from '@ampli/icon';
import { useBreakpoint } from '@ampli/hooks';
import { func, string, bool } from '@ampli/utils';

import { MecBadgeLarge, MecBadgeMobile } from '../mec-badge';
import { Strip } from '../strip-text';
import { BF_DISCOUNT_PERCENTAGE_TEXT } from '../../constants';

interface BannerHomeCourseProps {
  CTARedirectionURL: string;
  onClickScroll: () => void;
  isBlackFriday?: boolean;
}

const BannerHomeCourse: FunctionComponent<BannerHomeCourseProps> = ({
  CTARedirectionURL,
  onClickScroll,
  isBlackFriday = false,
}: BannerHomeCourseProps) => {
  const breakpoint = useBreakpoint();
  const isMobile = breakpoint !== 'desktop' && breakpoint !== 'tablet';

  // TODO: Remove after BF
  const blackFridayBannerConfig = css`
    background-image: linear-gradient(to right, black 10%, transparent 70%),
      linear-gradient(to left, black 10%, transparent 70%),
      linear-gradient(to bottom, black 1%, transparent 20%),
      linear-gradient(to top, black 1%, transparent 20%),
      url(${require('../../assets/banner-pagina-inicial-min.jpg')});

    ${mediaQuery('mobile')} {
      background-image: linear-gradient(to right, black 1%, transparent 75%),
        linear-gradient(to left, black 1%, transparent 75%),
        linear-gradient(to bottom, black 1%, transparent 20%),
        linear-gradient(to top, black 1%, transparent 20%),
        url(${require('../../assets/banner-pagina-inicial-mobile-min.jpg')});
    }

    .banner-button {
      box-shadow: 4px 4px 24px 4px #000;
    }

    .double-chevron-icon {
      color: ${getColor('text-white')};
    }
  `;

  return (
    <div
      className={cx(
        'banner-home-container',
        css`
          width: 100%;
          background-image: url(${require('../../assets/banner-pagina-inicial-min.jpg')});
          background-color: ${getColor('undergraduate-brand-base')};
          background-repeat: no-repeat;
          background-position: 75% 0%;
          background-size: cover;
          margin-bottom: ${getSpaceSize('large')};

          ${mediaQuery('mobile')} {
            background-image: url(${require('../../assets/banner-pagina-inicial-mobile-min.jpg')});
            background-color: ${getColor('undergraduate-brand-base')};
            background-repeat: no-repeat;
            background-position: 50% 0%;
            background-size: cover;
          }
        `,
        isBlackFriday && blackFridayBannerConfig
      )}
    >
      <div
        className={cx(
          'banner-home-content',
          css`
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            height: calc(100vh - ${px2rem(64)});
            min-height: ${px2grid(540)};

            ${mediaQuery('tablet')} {
              min-height: ${px2grid(700)};
            }

            ${mediaQuery('desktop')} {
              min-height: auto;
              flex-direction: row;
              align-items: flex-end;
              justify-content: center;
              height: calc(100vh - ${px2rem(90)});
              max-height: ${px2grid(690)};
            }
          `
        )}
      >
        {/* TODO: add clock display logic or remove after BF */}
        {isBlackFriday && (
          <Strip text={BF_DISCOUNT_PERCENTAGE_TEXT} repeatTextNTimes={14} />
        )}
        <div
          className={cx(
            'mec-badge-left-mirror-width',
            css`
              flex: 0;
              z-index: 2;

              ${mediaQuery('desktop')} {
                flex: 1;
                max-width: ${px2grid(285)};
              }
            `
          )}
        />
        <div
          className={cx(
            'banner-home-text',
            css`
              display: flex;
              flex-direction: column;
              align-items: center;
              z-index: 2;

              ${mediaQuery('mobile')} {
                flex: 1;
                justify-content: flex-end;
              }

              ${mediaQuery('desktop')} {
                margin-top: ${getSpaceSize('large')};
              }
            `
          )}
        >
          <Text
            as="h1"
            className={cx(
              'banner-description-wrap',
              css`
                display: flex;
                flex-direction: column;
                margin-bottom: ${getSpaceSize('xx-large')};
                margin-top: ${px2grid(40)};

                ${mediaQuery('mobile')} {
                  align-items: center;
                  margin-top: 0;
                }
              `
            )}
          >
            <Text
              size={isMobile ? '4rem' : px2grid(144)}
              color="white"
              className={css`
                font-weight: 100;
              `}
            >
              {'Educação '}
            </Text>
            <Text
              className={css`
                width: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-end;

                ${mediaQuery('tablet-min')} {
                  margin-left: 6%;
                }
              `}
            >
              <Text
                className={cx(
                  'banner-center-text',
                  css`
                    font-weight: normal;
                    margin-bottom: ${getSpaceSize('x-small')};
                    ${mediaQuery('mobile')} {
                      margin: ${getSpaceSize('small')} 0;
                      margin-left: ${px2grid(50)};
                    }
                  `
                )}
                size={isMobile ? 'xx-large' : 'huge'}
                color="white"
              >
                {'de qualidade '}
              </Text>

              <Text
                size={isMobile ? 'xx-large' : 'huge'}
                color="white"
                className={css`
                  font-weight: 600;
                `}
              >
                {'ao alcance de todos'}
              </Text>
            </Text>
          </Text>

          <div
            className={cx(
              'banner-home-footer',
              css`
                display: flex;
                flex-direction: column;
                flex: 1;
                justify-content: flex-end;
                align-items: center;
                padding-bottom: ${px2grid(50)};

                ${mediaQuery('mobile')} {
                  padding: 0;
                  flex: 0;
                }
              `
            )}
          >
            <Text
              as="h3"
              className={css`
                width: 90%;
                text-align: center;
              `}
            >
              <Text
                color="white"
                className={css`
                  ${mediaQuery('mobile')} {
                    display: block;
                  }
                `}
              >
                {'Não sabe por onde começar? '}
              </Text>
              <Text color="white">{'A Ampli te ajuda:'}</Text>
            </Text>
            <PrimaryButton
              as={Link}
              to={CTARedirectionURL}
              className={cx(
                'banner-button',
                css`
                  margin-top: ${getSpaceSize('small')};
                  background: ${GradientLinearWarning};
                `
              )}
            >
              <div
                className={css`
                  padding: 0 ${getSpaceSize('large')};
                `}
              >
                <SearchIcon
                  className={cx(
                    'banner-icon-search',
                    marginSize('x-small', 'right')
                  )}
                  color="white"
                />
                <Text color="white">
                  <strong>
                    {isMobile
                      ? 'Encontre o melhor curso'
                      : 'Encontre o melhor curso e estude 1 mês de graça!'}
                  </strong>
                </Text>
              </div>
            </PrimaryButton>

            {isMobile && (
              <Button onClick={onClickScroll}>
                <DoubleChevron
                  className={cx(
                    'double-chevron-icon',
                    css`
                      margin-top: ${px2grid(20)};
                      height: ${px2grid(30)};
                      width: ${px2grid(20)};
                    `
                  )}
                />
              </Button>
            )}
          </div>
        </div>
        <div
          className={cx(
            'mec-badge-wrap',
            css`
              align-self: flex-end;
              display: flex;
              justify-content: flex-start;
              padding-top: ${getSpaceSize('large')};
              ${mediaQuery('desktop')} {
                flex: 1;
                align-self: flex-start;
                justify-content: flex-end;
                max-width: ${px2grid(285)};
              }
            `
          )}
        >
          {isMobile ? (
            <MecBadgeMobile
              className={cx(
                'banner-mec-badge',
                css`
                  width: ${px2grid(135)};
                  height: ${px2grid(150)};
                `
              )}
            />
          ) : (
            <MecBadgeLarge
              className={cx(
                'banner-mec-badge',
                css`
                  width: ${px2grid(135)};
                  height: ${px2grid(180)};
                `
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BannerHomeCourse.propTypes = {
  CTARedirectionURL: string,
  onClickScroll: func,
  isBlackFriday: bool,
};

export default BannerHomeCourse;
