import React, { FunctionComponent } from 'react';
import { cx, css, px2grid } from '@ampli/ui';
import { string, bool } from '@ampli/utils';
import { EJAEnabled } from '../../config';

import DegreeCard from './degree-card';
import styles from './styles';
import { DefaultComponentWithClassNameProps } from '../../types';

// TODO: Remove this and add the props directly on the component once the
// feature toggle is not needed anymore
const EJAProps = {
  link: EJAEnabled
    ? '/eja'
    : 'https://ofertas.ampli.com.br/eja?utm_source=Site&utm_medium=Home&utm_campaign=Home&utm_content=367b96ed-fb37-461e-a83b-511f293d44c4&sellerId=367b96ed-fb37-461e-a83b-511f293d44c4',
  ...(!EJAEnabled && {
    openLinkInNewTab: true,
    externalLink: true,
  }),
};

// TODO: Remove after BF
// TODO: use only DefaultComponentWithClassNameProps
interface CardWrapperProps extends DefaultComponentWithClassNameProps {
  isBlackFriday?: boolean;
}

const CardWrapper: FunctionComponent<CardWrapperProps> = ({
  className,
  isBlackFriday = false,
  ...props
}: CardWrapperProps) => {
  const blackFridayDegreeCardStyleConfig = css`
    .ampli-overlay-wrapper {
      filter: brightness(0%);
    }
  `;

  return (
    <div
      className={cx(
        'card-wrapper',
        styles.cardWrapper,
        className,
        isBlackFriday && blackFridayDegreeCardStyleConfig
      )}
      {...props}
    >
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="Parachute"
          title="EJA"
          subtitle="(antigo Supletivo)"
          {...EJAProps}
        >
          Educação para Jovens e Adultos, do ensino fundamental ao ensino médio.
        </DegreeCard>
        <DegreeCard
          illustration="RunningMan"
          title="Graduação"
          link="/graduacao"
          isBlackFriday={isBlackFriday}
        >
          Cursos bacharelados, licenciaturas e tecnólogos a distância.
        </DegreeCard>
      </div>
      <div className={cx('card-wrapper-content', styles.cardWrapperContent)}>
        <DegreeCard
          illustration="RunningLady"
          title="Pós-"
          subtitle="Graduação"
          link="/pos-graduacao"
        >
          Cursos de especialização e MBA online.
        </DegreeCard>
        <DegreeCard
          illustration="PlayingTennis"
          title="Cursos"
          subtitle="Livres"
          link="https://cursolivre.ampli.com.br/"
          externalLink
          className={css`
            .illustration {
              max-width: ${px2grid(120)};
            }

            .subtitle {
              font-weight: bold !important;
            }
          `}
        >
          Cursos de curta duração com certificado.
        </DegreeCard>
      </div>
    </div>
  );
};

CardWrapper.propTypes = {
  className: string,
  isBlackFriday: bool,
};

export default CardWrapper;
