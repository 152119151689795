import React from 'react';
import {
  cx,
  css,
  mediaQuery,
  Flex,
  Text,
  Paragraph,
  Button,
  getColor,
  getSpaceSize,
} from '@ampli/ui';
import { string } from '@ampli/utils';
import { LEGAL_DOCUMENTS } from '../../constants';

const textWhiteColor = getColor('text-white');

const LinkButton = ({ className, ...props }) => (
  <Button
    {...props}
    as="a"
    target="_blank"
    size="micro"
    className={cx(
      'link-button',
      css`
        border: 1px solid ${textWhiteColor};
      `,
      className
    )}
  />
);
LinkButton.propTypes = {
  className: string,
};

const LegalDocuments = ({ className, ...props }) => (
  <Flex justifyContent="center" alignItems="center" direction="column">
    <div
      className={cx(
        'legal-documents',
        css`
          padding: ${getSpaceSize('xx-small')} 0 ${getSpaceSize('small')};

          ${mediaQuery('mobile')} {
            padding-bottom: ${getSpaceSize('x-large')};
          }
        `,
        className
      )}
      {...props}
    >
      <Paragraph marginSize="xx-small">
        <Text color="text-white" size="small">
          Documentos legais
        </Text>
      </Paragraph>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="center"
        wrap
        className={css`
          column-gap: ${getSpaceSize('x-small')};
          row-gap: ${getSpaceSize('x-small')};
        `}
      >
        <div>
          {LEGAL_DOCUMENTS.map((document, index) => (
            <LinkButton href={document.url} key={index}>
              <Text size="x-small" color="text-white">
                {document.label}
              </Text>
            </LinkButton>
          ))}
        </div>
      </Flex>
    </div>
  </Flex>
);

LegalDocuments.propTypes = {
  className: string,
};

export default LegalDocuments;
