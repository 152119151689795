import React from 'react';
import { Link } from 'react-router-dom';

import {
  css,
  cx,
  px2grid,
  px2rem,
  mediaQuery,
  ColorAccentBase,
  ColorNeutralDark,
} from '@ampli/ui';

import { string, arrayOf, shape, func, bool, number } from '@ampli/utils';

const MenuLink = ({ active, index, onClick, ...props }) => {
  return React.createElement(
    (onClick && 'button') || (props.target === '_blank' && 'a') || Link,
    {
      ...props,
      ...(onClick && {
        onClick: (e) => {
          e.preventDefault();
          onClick(e);
        },
      }),
      className: cx(
        'menu-link',
        active && 'active',
        css`
          background: transparent;
          border: 0;
          color: ${ColorNeutralDark};
          cursor: pointer;
          margin: ${px2grid(12)};
          text-decoration: none;

          &:active,
          &:hover,
          &.active {
            color: ${ColorAccentBase};
            text-decoration: none;
          }
          &.isFirst {
            margin-left: 0px;
          }
        `,
        index === 0 && 'isFirst',
        props.className
      ),
    }
  );
};
MenuLink.propTypes = {
  className: string,
  active: bool,
  onClick: func,
  index: number,
  target: string,
};

const MenuDesktop = ({ items, visibleSection, ...props }) => {
  return (
    <nav
      {...props}
      className={cx(
        'nav',
        css`
          display: flex;
          width: 100%;
          justify-content: center;
          flex-grow: 1;
          align-items: center;
          margin-left: ${px2rem(32)};

          ${mediaQuery('desktop')} {
            justify-content: flex-end;
          }
        `,
        props.className
      )}
    >
      <ul
        className={cx(
          'menu',
          css`
            display: none;
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
          `,
          ''
        )}
      >
        {items.map((item, index) => (
          <li
            key={`menu_item_${index}`}
            className={cx(
              'menu-item',
              css`
                align-self: center;
                padding: ${px2grid(20)} 0;
              `
            )}
          >
            <MenuLink
              active={visibleSection === item?.label}
              index={index}
              {...item}
            >
              {item.label}
            </MenuLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

MenuDesktop.propTypes = {
  className: string,
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  visibleSection: string,
};

export default MenuDesktop;
