import React from 'react';
import { Text, css, cx, getColor, px2grid, mediaQuery } from '@ampli/ui';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';

const TimPartnershipBanner = ({ link }) => (
  <Link
    to={link}
    className={cx(
      'tim-partnership-banner',
      css`
        z-index: 2;
        display: flex;
        justify-content: center;
        background: ${getColor('neutral-background')};
        border-radius: 0 0 ${px2grid(24)} ${px2grid(24)};
        position: absolute;
        width: 100%;
        height: ${px2grid(53)};
      `
    )}
  >
    <div
      className={cx(
        'tim-partnership-content',
        css`
          display: flex;
          align-items: center;
          margin: 0 ${px2grid(15)};
        `
      )}
    >
      <img
        src={require('../../assets/tim-logo-blue.png')}
        alt="Tim"
        className={cx(
          'tim-logo',
          css`
            width: ${px2grid(49)};
            height: ${px2grid(13)};
            margin-right: ${px2grid(16)};
          `
        )}
      />
      <Text
        color="neutral-title"
        className={cx(
          css`
            ${mediaQuery('mobile')} {
              font-size: 14px;
            }
          `
        )}
      >
        <strong>Novidade!</strong> Cursos livres de graça para clientes TIM.
      </Text>
    </div>
  </Link>
);

TimPartnershipBanner.propTypes = {
  link: string,
};

export default TimPartnershipBanner;
