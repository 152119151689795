import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useQuery } from '../hooks';
import { FIND_AFFILIATE_BY_GEOLOCATION_NEAR } from './queries';
export var useFindAffiliatesByGeolocationNear = function useFindAffiliatesByGeolocationNear(latitude, longitude, courseDegree) {
  var distance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 200;
  var options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  var _useQuery = useQuery(FIND_AFFILIATE_BY_GEOLOCATION_NEAR, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      longitude: longitude,
      latitude: latitude,
      distance: distance,
      courseDegree: courseDegree
    },
    skip: !longitude || !latitude || !distance
  })),
      loading = _useQuery.loading,
      data = _useQuery.data,
      rest = _objectWithoutProperties(_useQuery, ["loading", "data"]);

  var exists = loading || !data ? null : data.data;
  return _objectSpread({
    data: exists,
    loading: loading
  }, rest);
};