import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

export var STORAGE_SUBSCRIPTION_FORM = 'subscriptionForm'; // TODO: May be removed after EJAEnabled is not necessary anymore

export var ampli = process.env.AMPLI_ENV && require("@ampli/config/src/".concat(process.env.AMPLI_ENV)) || typeof window !== 'undefined' && window.__AMPLI__.ampli || JSON.parse(process.env.REACT_APP_AMPLI).ampli; // TODO: May be removed after EJAEnabled is not necessary anymore

var EJAEnabled = ampli.env !== 'production';
export var DEGREE_TYPES = _objectSpread({
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós-graduação'
}, EJAEnabled && {
  EJA: 'EJA'
});
export var INGRESS_MODALITY = 'IngressModality',
    AFFILIATE = 'Affiliate';
export var SPECIAL_FIELDS_TO_SHOW_BY_DEGREE_TYPE = {
  UNDERGRADUATE: [INGRESS_MODALITY, AFFILIATE],
  GRADUATE: [],
  EJA: [AFFILIATE]
};