import { css, px2grid, px2rem } from '@ampli/ui';

const degreeCard = css`
  flex: 1;

  & {
    .illustration {
      position: absolute;
      bottom: 0;
      left: ${px2grid(8)};
      max-width: ${px2grid(100)};
    }

    .card-content {
      height: 100%;
    }

    .title-wrapper {
      text-align: right;
    }

    .title {
      font-weight: 100;
    }

    .subtitle {
      font-weight: 200;
    }

    .description {
      text-align: right;
    }
  }
`;

const minWidthForFourCards = px2rem(954);
const minWidthForCardsInRow = px2rem(1440);

const cardWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: fit-content;

  @media (min-width: ${minWidthForFourCards}) {
    flex-direction: row;
  }

  & > .degree-card:not(:last-child) {
    margin-bottom: ${px2grid(20)};

    @media screen and (min-width: ${minWidthForFourCards}) {
      margin-bottom: 0;
      margin-right: ${px2grid(20)};
    }
  }
`;

const cardWrapperContent = css`
  display: flex;
  flex-direction: column;

  @media (min-width: ${minWidthForFourCards}) {
    margin: 0 ${px2grid(12)};
  }

  @media (min-width: ${minWidthForCardsInRow}) {
    flex-direction: row;
    margin: 0;
  }

  & > .degree-card:not(:last-child) {
    margin: ${px2grid(24)} 0;

    @media (min-width: ${minWidthForCardsInRow}) {
      margin: 0 3%;
    }
  }
`;

export default {
  degreeCard,
  cardWrapper,
  cardWrapperContent,
};
