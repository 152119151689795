// should be COMMONS_JS
module.exports = {
  graphqlUrl: 'https://graphql.ampli.com.br',
  subscriptionUrl: 'https://www.ampli.com.br',
  entranceExamUrl: 'https://www.ampli.com.br/processo-seletivo',
  baseUrl: 'https://www.ampli.com.br',
  loginUrl: 'https://ava.ampli.com.br/login',
  followSubscriptionUrl: 'https://ampli.com.br/processo-seletivo/login',
  env: 'production',
};
