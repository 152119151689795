import React from 'react';

import { useDialogState } from '@ampli/ui';
import { string, shape, arrayOf, func } from '@ampli/utils';

// eslint-disable-next-line import/no-unresolved
import { DesktopViewport, MobileViewport } from './base-header-viewports';
import HeaderContainer from './header-container';
import DialogMenu from './dialog-menu';

import { HEADER_LINKS } from '../../constants';
import {
  hideOnMaxWidth,
  hideOnMinWidth,
  HideModes,
} from '../../lib/hide-component-by-viewport.ts';

export const MIN_WIDTH_DESKTOP_HEADER = 1152;
const hideOnDesktop = hideOnMinWidth(
  MIN_WIDTH_DESKTOP_HEADER,
  HideModes.ZERO_HEIGHT
);
const hideOnMobile = hideOnMaxWidth(
  MIN_WIDTH_DESKTOP_HEADER,
  HideModes.ZERO_HEIGHT
);

const BaseHeader = ({
  items,
  onSubscribeClick,
  onLoginClick,
  visibleSection,
  ...props
}) => {
  const dialogState = useDialogState();

  const handleLoginClick = () => {
    if (!onLoginClick) return window?.open(HEADER_LINKS.login, '_blank');

    onLoginClick();
  };

  const checkSubscriptionLinkConfig = {
    href: HEADER_LINKS.followSubscription,
    target: '_blank',
  };

  const loginLinkConfig = {
    href: HEADER_LINKS.login,
    target: '_blank',
  };

  const onClickMobileMenu = () =>
    dialogState[dialogState.visible ? 'hide' : 'show']();

  return (
    <HeaderContainer>
      <DesktopViewport
        items={items}
        loginLinkConfig={loginLinkConfig}
        checkSubscriptionLinkConfig={checkSubscriptionLinkConfig}
        onSubscribeClick={onSubscribeClick}
        visibleSection={visibleSection}
        className={hideOnMobile}
        {...props}
      />
      <MobileViewport
        onSubscribeClick={onSubscribeClick}
        onClickMenu={onClickMobileMenu}
        className={hideOnDesktop}
        {...props}
      />

      <DialogMenu
        items={items}
        onLoginClick={handleLoginClick}
        onSubscribeClick={onSubscribeClick}
        dialogState={dialogState}
      />
    </HeaderContainer>
  );
};

BaseHeader.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  onLoginClick: func,
  onFollowSubscriptionClick: func,
  onSubscribeClick: func,
  visibleSection: string,
};

export default BaseHeader;
