import { DateTime, size, filter, map, differenceBy, find } from '@ampli/utils';
import { dateField } from './commons';
var inProgressStatus = ['IN_PROGRESS', 'IN_RECOVERY', 'AWAITING_CHOICE', 'AWAITING_ENROLLMENT'];
var takenStatus = ['PASSED', 'FAILED'];
var ignoredStatus = ['CEASED'];
var approvedStatus = 'PASSED';
var subjectEnrollmentsInProgress = {
  read: function read(cached, _ref) {
    var readField = _ref.readField;
    if (cached) return cached;
    var subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    var inProgressRefs = filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      return inProgressStatus.includes(status);
    });
    var startedRefs = filter(inProgressRefs, function (subjectEnrollmentRef) {
      var startDate = DateTime.fromISO(readField('startDate', subjectEnrollmentRef));
      var status = readField('status', subjectEnrollmentRef);
      return DateTime.local() >= startDate || inProgressStatus.includes(status);
    });
    var takenRefs = filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      return takenStatus.includes(status);
    });
    return find([startedRefs, inProgressRefs, takenRefs, subjectEnrollmentRefs], size);
  }
};
var subjectEnrollmentsTaken = {
  read: function read(cached, _ref2) {
    var readField = _ref2.readField;
    if (cached) return cached;
    var subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    return filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      return takenStatus.includes(status);
    });
  }
};
var subjectEnrollmentsToBeTaken = {
  read: function read(cached, _ref3) {
    var readField = _ref3.readField;
    if (cached) return cached;
    var subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    var courseSubjectRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      },
      from: readField('course')
    });
    var subjectRefs = map(courseSubjectRefs, function (courseSubjectRef) {
      return readField('subject', courseSubjectRef);
    });
    var subjectTakenRefs = map(filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      return !ignoredStatus.includes(status);
    }), function (subjectEnrollmentRef) {
      return readField('subject', subjectEnrollmentRef);
    });
    var electiveSubjectTakenRefs = map(filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      var isElective = readField('isElective', subjectEnrollmentRef);
      return isElective && !ignoredStatus.includes(status);
    }), function (subjectEnrollmentRef) {
      return readField('subject', subjectEnrollmentRef);
    });
    var electiveCounter = electiveSubjectTakenRefs.length;
    return map(filter(subjectRefs, function (subject) {
      if (!subject) {
        electiveCounter = electiveCounter - 1;
        return electiveCounter < 0;
      }

      return subjectTakenRefs.filter(function (sub) {
        return !!sub;
      }).map(function (sub) {
        return sub.__ref;
      }).indexOf(subject.__ref) < 0;
    }), function (subject) {
      return {
        subject: subject
      };
    });
  }
};
var progress = {
  read: function read(cached, _ref4) {
    var readField = _ref4.readField;
    if (cached) return cached;
    var courseSubjectRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      },
      from: readField('course')
    });
    var subjectEnrollmentRefs = readField({
      fieldName: 'subjects',
      args: {
        full: true
      }
    });
    var approvedSubjectEnrollmentRefs = filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      var status = readField('status', subjectEnrollmentRef);
      return status === approvedStatus;
    });
    var completed = approvedSubjectEnrollmentRefs ? approvedSubjectEnrollmentRefs.length : 0;
    var quantity = courseSubjectRefs ? courseSubjectRefs.length : 0;
    var percentage = completed / quantity;
    var percentageLabel = "".concat(Math.round(percentage * 100), "%");
    return {
      completed: completed,
      quantity: quantity,
      percentage: percentage,
      percentageLabel: percentageLabel
    };
  }
};
var numberOfSubjects = {
  read: function read(cached, _ref5) {
    var readField = _ref5.readField;
    if (cached) return cached;
    var courseRef = readField('course');
    var subjectRefs = readField('subjects', courseRef);
    return size(subjectRefs);
  }
};
var numberOfCompletedSubjects = {
  read: function read(cached, _ref6) {
    var readField = _ref6.readField;
    if (cached) return cached;
    var subjectEnrollmentRefs = readField('subjects');
    return size(filter(subjectEnrollmentRefs, function (subjectEnrollmentRef) {
      return ['PASSED', 'COMPLETED'].includes(readField('status', subjectEnrollmentRef));
    }));
  }
};
export default {
  fields: {
    startDate: dateField,
    estimatedEndDate: dateField,
    subjectEnrollmentsInProgress: subjectEnrollmentsInProgress,
    subjectEnrollmentsTaken: subjectEnrollmentsTaken,
    subjectEnrollmentsToBeTaken: subjectEnrollmentsToBeTaken,
    progress: progress,
    numberOfSubjects: numberOfSubjects,
    numberOfCompletedSubjects: numberOfCompletedSubjects
  }
};