// should be COMMONS_JS
module.exports = {
  graphqlUrl: 'http://localhost:3000',
  subscriptionUrl: 'http://localhost:7001',
  entranceExamUrl: 'http://localhost:7002/processo-seletivo',
  baseUrl: 'https://growth.stg.ampli.com.br',
  loginUrl: 'http://ava-staging.ampli.com.br/login',
  followSubscriptionUrl: 'https://staging.ampli.com.br/processo-seletivo/login',
  env: 'local',
};
